import React from 'react'
import { Layout, Stack, Main } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const PageGames = props => (
    <Layout {...props}>
        <Seo title='Games' />
        <Stack>
            <Main>
                <iframe src="https://m.mgames.io/" width="100%"  className="cm-content-frame"></iframe>
            </Main>
        </Stack>
    </Layout>
)

export default PageGames
